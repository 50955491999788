<template>
  <ui-list
    :heading="heading"
    :headings="['SID', 'status', $t('changed'), $t('completed'), '']"
    :items="students"
  >
    <template v-slot:default="props">
      <ui-list-data :text="props.item.studentId"></ui-list-data>
      <ui-list-data
        :text="props.item.portStatus ? $t('misc.open') : $t('misc.closed')"
      ></ui-list-data>

      <ui-list-data>
        <span v-if="props.item.changed">{{
          $d(
            new Date(props.item.changed),
            'short',
            $i18n.locale === 'no' ? 'no' : 'en'
          )
        }}</span>
      </ui-list-data>

      <ui-list-data>
        <span v-if="props.item.courseCompleted">{{
          $d(
            new Date(props.item.courseCompleted),
            'long',
            $i18n.locale === 'no' ? 'no' : 'en'
          )
        }}</span>
        <span v-else>-</span>
      </ui-list-data>

      <ui-list-data small>
        <ui-link
          size="small"
          type="danger"
          uppercase
          @click="onDeleteStudent(props.item)"
        >
          {{ $t('actions.delete') }}
        </ui-link>
        <ui-link
          class="ml-2"
          type="success"
          size="small"
          uppercase
          @click="onOpenPort(props.item)"
          >{{ $t('misc.open') }}
        </ui-link>
      </ui-list-data>
    </template>
  </ui-list>
</template>

<script>
import UiLink from '@/components/ui/UiLink';
import UiList from '@/components/ui/UiList';
import UiListData from '@/components/ui/UiListData';
export default {
  props: {
    heading: {
      type: String
    },
    students: {
      type: Array,
      required: true
    }
  },

  components: {
    UiLink,
    UiList,
    UiListData
  },

  methods: {
    onOpenPort(student) {
      this.$emit('onOpenPort', student);
    },

    onDeleteStudent(student) {
      this.$emit('onDeleteStudent', student);
    }
  }
};
</script>

<style></style>

<template>
  <div v-loading="loading">
    <div v-if="building">
      <ui-header :heading="building.name"> </ui-header>

      <course-list
        :building="building"
        @onPublish="handlePublish"
        :heading="$tc('models.course', 2)"
        :courses="courses"
        @onShowLockModal="onShowLock"
        @onShowUnlockModal="onShowUnlock"
      />

      <department-list
        class="mt-8"
        :heading="$tc('models.department', 2)"
        :departments="departments"
      >
        <template v-slot:actions>
          <ui-link
            type="text"
            :route="{
              name: 'buildings-create-department',
              params: { buildingId: building.id }
            }"
            >{{ `${$t('actions.add')} ${$tc('models.department')}` }}</ui-link
          >
        </template>
      </department-list>
      <auth-checker :accessRoles="['SuperAdmin']">
        <student-view class="mt-8" :buildingId="building.id"></student-view>
      </auth-checker>
    </div>
  </div>
</template>

<script>
import UiHeader from '@/components/ui/UiHeader';
import UiLink from '@/components/ui/UiLink';
import DepartmentList from '@/app/departments/components/DepartmentList';
import CourseList from '@/app/courses/components/CourseList';
import StudentView from '@/app/students/views/StudentView';
import AuthChecker from '../../auth/components/AuthChecker';
import {
  getBuilding,
  getBuildingDepartments,
  getBuildingCourses
} from '../api';
import {publishCourse, toggleCourseLock} from '../../courses/api';

export default {
  data() {
    return {
      building: null,
      departments: [],
      courses: [],
      students: [],
      loading: false,
      buildingName: getBuilding(this.$route.params.id).name
    };
  },

  components: {
    UiHeader,
    UiLink,
    DepartmentList,
    CourseList,
    StudentView,
    AuthChecker
  },

  methods: {

    onShowLock(course) {
      this.lockTarget = course;
      this.showLockModal = true;
    },

    onLockModalCancel() {
      this.showLockModal = false;
    },

    onShowUnlock(course) {
      this.lockTarget = course;
      this.showUnlockModal = true;
    },

    onUnlockModalCancel() {
      this.showUnlockModal = false;
    },

    async handleToggleLock() {
      const errorMsg = { message: this.$t('errors.general'), type: 'error' };
      if (this.lockTarget === null) {
        this.$message(errorMsg);
        return;
      }
      this.loading = true;
      try {
        const id = this.lockTarget.id;
        const state = await toggleCourseLock(id);
        this.loading = false;
        this.courses.find(c => c.id === id).locked = state;
        this.lockTarget = null;
        const msg = {
          message: this.$t('feedback.update_success'),
          type: 'success'
        };
        this.$message(msg);
        this.showLockModal = false;
        this.showUnlockModal = false;
      } catch (err) {
        this.loading = false;
        this.lockTarget = null;
        this.$message(errorMsg);
      }
    },

    async getBuilding() {
      const building = await getBuilding(this.$route.params.id);
      this.building = building;
    },

    async getDepartments() {
      const departments = await getBuildingDepartments(this.$route.params.id);
      this.departments = departments.sort((a, b) => (a.name > b.name ? 1 : -1));
    },

    async getCourses() {
      const courses = await getBuildingCourses(this.$route.params.id);
      this.courses = courses;
    },

    async handlePublish(toPublish) {
      this.loading = true;
      try {
        const course = await publishCourse(toPublish.id);
        this.courses.map(c => {
          if (c.id === course.id) {
            c.published = course.published;
          }
        });
        this.loading = false;
      } catch (error) {
        this.$message({ message: this.$t('errors.general'), type: 'error' });
        this.loading = false;
      }
    }
  },

  async created() {
    this.loading = true;
    await this.getBuilding();
    await this.getDepartments();
    await this.getCourses();
    this.loading = false;
  },

  async mounted() {
    let buildingId = this.$route.params.id;
    let building = await getBuilding(buildingId);
    let buildingName = building.name;
    this.$store.commit('changeBreadcrumbs', [
      {name: "models.buildings", link: "/app/buildings/"},
      {name: buildingName, link: "/app/buildings/" + buildingId}
    ]);
  },
};
</script>

<template>
  <div v-loading="loading">
    <student-list
      :heading="$tc('models.student', 2)"
      :students="students"
      @onOpenPort="handleOpenPort"
      @onDeleteStudent="handleDeleteStudent"
    />

    <student-creator
      class="mt-8"
      @onSaveImport="handleSaveImport"
      :buildingId="buildingId"
    />
  </div>
</template>

<script>
import StudentList from '@/app/students/components/StudentList';
import StudentCreator from '@/app/students/components/StudentCreator';
import {
  getBuildingStudents,
  createStudents,
  openPort,
  deleteStudent
} from '../api';

export default {
  props: {
    buildingId: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      students: [],
      loading: false
    };
  },

  components: {
    StudentList,
    StudentCreator
  },

  methods: {
    async getStudents() {
      const buildingId = this.$props.buildingId;
      this.loading = true;
      try {
        const students = await getBuildingStudents(buildingId);
        this.students = students;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$message({ message: this.$t('error.general'), type: 'error' });
      }
    },

    async handleSaveImport(importList) {
      this.loading = true;
      try {
        const payload = {
          buildingId: this.$props.buildingId,
          students: importList
        };
        await createStudents(payload);
        await this.getStudents();
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$message({ message: this.$t('errors.general'), type: 'error' });
      }
    },

    async handleOpenPort(student) {
      this.loading = true;
      try {
        await openPort(student);
        student.portStatus = true;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$message({ message: this.$t('errors.general'), type: 'error' });
      }
    },

    async handleDeleteStudent(student) {
      this.loading = true;
      const studentId = student.id;
      try {
        const deletedId = await deleteStudent(studentId);
        this.students = this.students.filter(s => s.id !== deletedId);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$message({ message: this.$t('errors.general'), type: 'error' });
      }
    }
  },

  async created() {
    await this.getStudents();
  }
};
</script>

<style></style>

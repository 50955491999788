import api from '@/services/api';

export const getBuildingStudents = async buildingId => {
  const result = await api.get(`/students/${buildingId}`);
  return result.data;
};

export const createStudents = async payload => {
  const result = await api.post(`/students`, payload);
  return result.data;
};

export const openPort = async student => {
  const result = await api.post(`/students/open`, student);
  return result.data;
};

export const deleteStudent = async studentId => {
  const result = await api.delete(`/students/${studentId}`);
  return result.data;
};
